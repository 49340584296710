import React from 'react';
import './Tos.css'; // Create this CSS file for styling

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>Welcome to <strong>EarnFlix</strong>! By using our services, you agree to these Terms and Conditions. Please read them carefully.</p>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using EarnFlix, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions, as well as our Privacy Policy.
        </p>
      </section>

      <section>
        <h2>2. User Accounts</h2>
        <p>
          To access certain features, you may need to create a user account. You are responsible for maintaining account confidentiality and all activities under your account.
        </p>
      </section>

      <section>
        <h2>3. Prohibited Activities</h2>
        <ul>
          <li>Violate applicable laws or regulations</li>
          <li>Interfere with EarnFlix's operation or connected servers and networks</li>
          <li>Access unauthorized areas or user accounts</li>
          <li>Engage in fraudulent or deceptive behavior</li>
        </ul>
      </section>

      <section>
        <h2>4. Changes to Terms and Conditions</h2>
        <p>
          EarnFlix reserves the right to update or modify these Terms and Conditions at any time without prior notice. Changes are effective immediately upon posting. Continued use constitutes acceptance of the revised Terms and Conditions.
        </p>
      </section>

      <section>
        <h2>5. Termination</h2>
        <p>
          EarnFlix reserves the right to terminate or suspend your access to services at any time, with or without cause, and without prior notice.
        </p>
      </section>

      <section>
        <h2>6. Contact Us</h2>
        <p>
          If you have questions or concerns, please contact us at <a href="mailto:mohamedali116500@gmail.com">mohamedali116500@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
