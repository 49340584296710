import logo from './logo.svg';
import './App.css';
import AboutUs from './compnents/Abouts./AboutUS';
import TermsAndConditions from './compnents/TOS/Tos';
import PrivacyPolicy from './compnents/PrivacyPOlicy/Privacy';

function App() {
  return (
  // <AboutUs/>
  <TermsAndConditions/>
  // <PrivacyPolicy/>
  );
}

export default App;
